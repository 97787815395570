import { Tokens, marked } from 'marked';

export const markdownRender = {
    heading({ tokens }: Tokens.Heading): string {
        const headingText = tokens
            .map((token) => {
                if (token.type === 'text') return token.text;
                return '';
            })
            .join('');
        return `<b class='bold-markdown'>${headingText}</b><br><br>`;
    },
    paragraph({ tokens }: Tokens.Paragraph): string {
        const paragraphText = tokens
            .map((token) => {
                if (token.type === 'text') return token.text;
                if (token.type === 'strong') return `<b>${token.text}</b>`;
                if (token.type === 'em') return `<i>${token.text}</i>`;
                return '';
            })
            .join('');
        return `${paragraphText}<br><br>`;
    },
    strong({ tokens }: Tokens.Strong): string {
        return tokens[0].type === 'text' ? `<b>${tokens[0].text}</b>` : '';
    },
    em({ tokens }: Tokens.Em): string {
        return tokens[0].type === 'text' ? `<i>${tokens[0].text}</i>` : '';
    },
};

export async function convertMarkdownToHtml(markdown: string) {
    marked.use({ renderer: markdownRender });

    const htmlContent = await marked(markdown);

    return htmlContent;
}
