import type { FC } from 'react';
import React from 'react';
import type { PaymentPlanHeaderProps } from './PaymentPlanHeader.types';
import {
    formatCreditBalance,
    formatDaysRemaining,
    formatPaymentStatus,
    isLifetime,
} from '../PaymentPlan.functions';
import { useScreenSize } from '~hooks';
import { Resubscribe } from '../Resubscribe';

import './PaymentPlanHeader.styles.scss';
import { useTranslation } from 'react-i18next';

export const PaymentPlanHeader: FC<PaymentPlanHeaderProps> = (props) => {
    const { open, data, planStatus, token, customerId } = props;
    const { isMobile } = useScreenSize();

    const planStatusCSSClass =
        data && data.status === 'active' ? 'active-plan' : 'inactive-plan';

    const isActive = data && data.status === 'active';

    const { t } = useTranslation();

    const planName =
        data && isLifetime(data)
            ? 'Lifetime'
            : data && data?.planType
              ? data?.planType
              : 'Basic Plan';

    const planStatusMsg =
        data && isLifetime(data)
            ? 'Lifetime'
            : isActive
              ? formatDaysRemaining(data, t('days_remaining'))
              : data && data.status
                ? formatPaymentStatus(data.status, t)
                : 'Loading...';

    const balance =
        data && isLifetime(data) ? '' : formatCreditBalance(data?.balance, t);

    const coupon =
        data && data.couponPercentOff ? `${data.couponPercentOff}% OFF` : '';

    if (!open) return null;

    return (
        <div className="payment-plan-header">
            {!isMobile && (
                <div className="information">
                    <div className="plan-tags">
                        <h1>{planName}</h1>
                        {balance && isActive && (
                            <div className="plan-tag">
                                <p>{balance}</p>
                            </div>
                        )}
                        {coupon && (
                            <div className="plan-tag">
                                <p>{coupon}</p>
                            </div>
                        )}
                    </div>
                    <p className="plan-short-description">
                        {t('unlimited_studybuddy')}
                    </p>
                </div>
            )}

            {isMobile && (
                <div className="information">
                    <div className="plan-type">
                        <h1>{planName}</h1>
                        <div className="plan-tags">
                            {balance && isActive && (
                                <div className="plan-tag">
                                    <p>{balance}</p>
                                </div>
                            )}
                            {coupon && isActive && (
                                <div className="plan-tag">
                                    <p>{coupon}</p>
                                </div>
                            )}
                        </div>
                        <p className="plan-short-description">
                            {t('unlimited_studybuddy')}
                        </p>
                    </div>
                    <div className={`status ${planStatusCSSClass}`}>
                        <p>{planStatusMsg}</p>
                    </div>
                </div>
            )}

            <div className="status-and-actions">
                {!isMobile && (
                    <div className={`status ${planStatusCSSClass}`}>
                        <p>{planStatusMsg}</p>
                    </div>
                )}
                {planStatus && (
                    <Resubscribe
                        status={planStatus}
                        token={token}
                        customerId={customerId}
                    />
                )}
            </div>
        </div>
    );
};
