import React from 'react';

import type { FC } from 'react';
import type { LoaderProps } from './Loader.types';

import './Loader.styles.scss';

export const FancyLoader: FC<LoaderProps> = ({ size, color, relative }) => {
    const loaderStyle = {
        width: size || '80px',
        height: size || '80px',
    };
    const loaderColor = {
        borderTopColor: color || '#4F95FF',
        borderBottomColor: color || '#4F95FF',
    };
    return (
        <div
            className="w-100 h-100 d-flex justify-content-center align-items-center"
            style={{ background: '#fff', position: 'absolute' }}
        >
            <div
                className={`fancy-loader ${relative ? 'fancy-loader-relative' : null}`}
                style={loaderStyle}
            >
                <div className="fancy-loader-inner" style={loaderColor}>
                    <div
                        className="fancy-loader-inner"
                        style={loaderColor}
                    ></div>
                </div>
            </div>
        </div>
    );
};
