import React, { FC } from 'react';

import './SummerCancelPopup.styles.scss';

type Props = {
    open: boolean;
    onClose: () => void;
    isLoading: boolean;
    onCtaClickHandler: () => void;
    onNoThanksClickHandler: () => void;
};

export const SummerCancelPopup: FC<Props> = (props) => {
    const {
        open,
        onClose,
        isLoading,
        onCtaClickHandler,
        onNoThanksClickHandler,
    } = props;

    const handleClose = () => {
        onClose();
    };

    if (!open) {
        return null;
    }

    return (
        <div className="summer-cancel-popup">
            <div className="summer-cancel-content">
                <h1>Don’t Cancel, Just Pause! ⛱️</h1>
                <p>
                    We’d hate to see you leave just because of summer break!
                    <br />
                    <strong>
                        That’s why we’re offering you FREE StudyBuddy access all
                        summer long.
                    </strong>
                </p>
                <p>
                    Pause your subscription instead of canceling and enjoy
                    uninterrupted access for <strong>FREE.</strong> Billing will
                    resume at the start of the school year{' '}
                    <strong>(September 1st).</strong>
                </p>
                <button
                    className="cta-btn"
                    onClick={onCtaClickHandler}
                    disabled={isLoading}
                >
                    Pause Now & Get Free Access
                </button>
                <p className="no-thanks-btn" onClick={onNoThanksClickHandler}>
                    I’d rather pass
                </p>
                <img className='summber-break' src="/assets/images/summer-break.png" alt="summer" />
            </div>
            <div className="background-modal" onClick={handleClose}></div>
        </div>
    );
};
