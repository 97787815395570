import { lazy } from 'react';

export const Login = lazy(() =>
    import('~pages/Login').then((module) => ({ default: module.Login })),
);
export const Register = lazy(() =>
    import('~pages/Register').then((module) => ({ default: module.Register })),
);
export const Dashboard = lazy(() =>
    import('~pages/Dashboard').then((module) => ({
        default: module.Dashboard,
    })),
);
export const DashboardProfile = lazy(() =>
    import('~pages/Dashboard/DashboardProfile').then((module) => ({
        default: module.DashboardProfile,
    })),
);
export const DashboardContact = lazy(() =>
    import('~pages/Dashboard/DashboardContact').then((module) => ({
        default: module.DashboardContact,
    })),
);
export const PaymentDetails = lazy(() =>
    import('~pages/PaymentDetails').then((module) => ({
        default: module.PaymentDetails,
    })),
);
export const ForgetPassword = lazy(() =>
    import('~pages/ForgetPassword').then((module) => ({
        default: module.ForgetPassword,
    })),
);
export const Homepage = lazy(() =>
    import('~pages/Homepage').then((module) => ({ default: module.Homepage })),
);
export const NewHomepage = lazy(() =>
    import('~pages/New_Homepage').then((module) => ({
        default: module.NewHomepage,
    })),
);
export const Privacy = lazy(() =>
    import('~pages/Privacy').then((module) => ({ default: module.Privacy })),
);
export const Terms = lazy(() =>
    import('~pages/Terms').then((module) => ({ default: module.Terms })),
);
export const WriterDashboard = lazy(() =>
    import('~pages/WriterDashboard').then((module) => ({
        default: module.WriterDashboard,
    })),
);
export const WriterContentgenerator = lazy(() =>
    import(
        '~pages/WriterDashboard/ContentGenerator/WriterContentGenerator'
    ).then((module) => ({ default: module.WriterContentgenerator })),
);
export const WriterDashboardWrapper = lazy(() =>
    import('~pages/WriterDashboard/Layout/WriterDashboardWrapper').then(
        (module) => ({ default: module.WriterDashboardWrapper }),
    ),
);
export const WriterTextHumanizer = lazy(() =>
    import('~pages/WriterDashboard/AIHumanizer/WriterTextHumanizer').then(
        (module) => ({ default: module.WriterTextHumanizer }),
    ),
);
export const WriterDocument = lazy(() =>
    import('~pages/WriterDashboard/Documents/WriterDocument').then(
        (module) => ({ default: module.WriterDocument }),
    ),
);
export const WriterAIDetector = lazy(() =>
    import('~pages/WriterDashboard/AiDetector/WriterAIDetector').then(
        (module) => ({ default: module.WriterAIDetector }),
    ),
);
export const WriterTool = lazy(() =>
    import('~pages/WriterTool').then((module) => ({
        default: module.WriterTool,
    })),
);
export const AppRedirect = lazy(() =>
    import('~pages/AppRedirect/Appredirect').then((module) => ({
        default: module.default,
    })),
);
export const AccountDelete = lazy(() =>
    import('~pages/AccountDelete').then((module) => ({
        default: module.AccountDelete,
    })),
);

export const SignOutRedirect = lazy(() =>
    import('~components/SignOutRedirect').then((module) => ({
        default: module.SignOutRedirect,
    })),
);
