import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * @description this hook scrolls into view to the element based on the id
 * @readonly please change the duration if the page is taking time to load
 */
export function useScrollIntoView(duration?: number) {
    const location = useLocation();
    const [shouldRerender, setShouldRerender] = useState(false);

    const toggleRender = () => {
        setShouldRerender(!shouldRerender);
    };

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (location.hash) {
            timeout = setTimeout(() => {
                const id = location.hash?.replace('#', '');
                let hasEle = document.getElementById(id);

                hasEle?.scrollIntoView({ behavior: 'smooth' });
            }, duration ?? 200);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [location.hash, duration, shouldRerender]);

    return {
        toggleRender,
    };
}
