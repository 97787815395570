import { API } from 'aws-amplify';
import { API_NAME_STRIPE_AWS, GET_CANCELLATION_HASH, PAUSE_SUBSCRIPTION_UNTIL_SEPTEMBER_API } from '~constants';
import { getCurrentEnvironment, logger } from '~utils';

export const newCancellation = async (token: string | undefined, customerId: string) => {
    logger.debug('Pausing subscription... >>>', customerId);
    try {
        const response = await API.post(
            API_NAME_STRIPE_AWS,
            PAUSE_SUBSCRIPTION_UNTIL_SEPTEMBER_API,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    customerId: customerId
                },
            },
        );
        logger.debug('Pausing subscription response:', response);
        return response;
    } catch (e: any) {
        logger.error(e);
        return e?.response.data || "failed";
    }

}

export const getChurnkeyHash = async (
    token: string | undefined,
    customerId: string
) => {
    logger.debug('Getting Hash...');
    try {
        const response = await API.post(
            API_NAME_STRIPE_AWS,
            GET_CANCELLATION_HASH,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    stripeId: customerId
                },
            },
        );
        logger.debug('Hash response:', response);
        return response;
    } catch (e: any) {
        logger.error(e);
        return e.response.data || "failed";
    }
};

export const submitCancellationEvents = (email: string) => {
    logger.debug('submitting cancellation events with email', email);
    const { isProd } = getCurrentEnvironment()
    if (!email || !isProd) {
        logger.debug("No email or not in prod, not submitting cancellation events");
        return;
    };
    try {
        // identify
        (window as any).klaviyo.identify({
            '$email': email
        })
            // track
            .then(() => {
                (window as any).klaviyo.push(["track", "Canceled Subscription", {
                    "$event_id": new Date().getTime(),
                }]);
            });
    } catch {
        // do nothing 
    }
}