import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { store } from '~redux';
import reportWebVitals from './reportWebVitals';
import { PAYPAL_CLIENT_ID } from '~constants';

import './index.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
root.render(
    <Provider store={store}>
        <PayPalScriptProvider options={{ clientId: PAYPAL_CLIENT_ID, vault: true }}>
            <App />
        </PayPalScriptProvider>
    </Provider>,
);
reportWebVitals();
