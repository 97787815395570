import { toast } from 'react-toastify';

export const useSnackbar = () => {
    const successSnackbar = (message: string, duration: number = 2000) => {
        toast.success(message, {
            position: 'bottom-center',
            autoClose: duration,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: 'light',
        });
    };
    const errorSnackbar = (message: string, duration: number = 5000) => {
        toast.error(message, {
            position: 'top-center',
            autoClose: duration,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: 'light',
        });
    };
    const infoSnackbar = (message: string, duration: number = 5000) => {
        toast.info(message, {
            position: 'bottom-center',
            autoClose: duration,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: 'light',
        });
    };

    return { successSnackbar, errorSnackbar, infoSnackbar };
};
