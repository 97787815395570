import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '~/hooks';
import { FancyLoader } from '~components/Loader';
import { AppRoutes } from '~constants';
import { logger } from '~utils';

const PrivateRoutes = () => {
    const [loading, setLoading] = useState(true);

    const { updateAuthState, isAuthenticated, planStatus } = useAuth();

    const checkAuth = async () => {
        logger.debug('priv auth >>>:');
        await updateAuthState();
        setLoading(false);
    };

    useEffect(() => {
        checkAuth();
    }, []);

    if (loading) {
        return <FancyLoader />;
    }

    if (!loading && isAuthenticated && !planStatus) {
        logger.debug('isAuthenticated plan >>>:', isAuthenticated);
        return <Navigate to={AppRoutes.Signin_Redirect} />;
    }

    if (!loading && !isAuthenticated) {
        logger.debug('isAuthenticated >>>:', isAuthenticated);
        return <Navigate to={AppRoutes.Login} />;
    }

    return <Outlet />;
};

export default PrivateRoutes;
