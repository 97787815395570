import { API } from 'aws-amplify';
import { API_NAME_STRIPE_AWS, POST_PAYMENT_API } from '~constants';
import { logger } from '~utils';

export type PostPaymentPayload = {
    phone?: string,
    referralId?: string,
    status?: string,
    fbclid?: string,
    fbp?: string
};

export const postPaymentApi = async (token: string, body: PostPaymentPayload) => {
    try {
        if (!token || !body) {
            return;
        }
        const response = await API.post(API_NAME_STRIPE_AWS, POST_PAYMENT_API, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body
        });
        const msg = response.message;
        return msg;
    } catch (err: any) {
        logger.error(err);
    }
};