// src/store/userData.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BillingDetailReducer } from './billingReducer.types';

const initialState: { billingData: BillingDetailReducer | null } = {
    billingData: null,
};

const billingDataSlice = createSlice({
    name: 'billingDataSlice',
    initialState,
    reducers: {
        setBillingInfo(state, action: PayloadAction<BillingDetailReducer>) {
            if (state) {
                state.billingData = action.payload;
            }
        },
    },
});

export const { setBillingInfo } = billingDataSlice.actions;
export default billingDataSlice.reducer;
