// src/store/userData.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDataState } from './userReducer.types';

const initialState: UserDataState = {
    data: null,
    isAuthenticated: false,
    planStatus: '',
    token: '',
};

const userDataSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<UserDataState['data']>) {
            state.data = action.payload;
            state.isAuthenticated = true;
            state.planStatus =
                action.payload?.attributes['custom:stripeAccountStatus'] ||  action.payload?.attributes['custom:paypalAccountStatus'];
            state.token = action.payload?.signInUserSession?.idToken?.jwtToken;
        },
        clearUser(state) {
            state.data = null;
            state.isAuthenticated = false;
            state.planStatus = '';
            state.token = '';
        },
    },
});

export const { setUser, clearUser } = userDataSlice.actions;
export default userDataSlice.reducer;
