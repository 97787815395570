import { STAGE_URL } from '~constants';

export const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const computeDaysLeft = (timestamp: number): number => {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;

    const currentTime = new Date().getTime();
    const providedTime = timestamp * 1000; // Convert provided timestamp to milliseconds

    const timeDifference = Math.abs(currentTime - providedTime);

    const daysDifference = Math.floor(timeDifference / millisecondsPerDay);

    return daysDifference;
};

export const computeTodaysDate = () => {
    const today = new Date();
    const day = today.getDate();
    const month = monthNames[today.getMonth()];
    const year = today.getFullYear();
    const formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
};

export const covertTimestampToDate = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
};

export const setCookie = (
    cname: string,
    cvalue: string,
    expiryDays: number,
) => {
    const d = new Date();
    d.setTime(d.getTime() + expiryDays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    // Can expire soon since this is just used to auth into local storage for the extension
    document.cookie =
        cname + '=' + cvalue + ';' + expires + ';samesite=lax; secure';
};

export const getCurrentEnvironment = () => {
    // Handle the different redirect URI for local/production
    const isLocalhost = Boolean(window.location.hostname === 'localhost');
    const isStage = Boolean(window.location.origin === STAGE_URL);
    const isProd = Boolean(window.location.origin === 'https://studybuddy.gg');
    return { isLocalhost, isStage, isProd };
};

export const getChurnkeyEnvironment = () => {
    const { isProd } = getCurrentEnvironment();
    return isProd ? 'live' : 'test';
};

export const splitName = (fullName: string) => {
    // Split the full name into an array of parts
    var parts = fullName.trim().split(' ');

    // If there is only one part, assume it is the first name
    if (parts.length === 1) {
        return {
            firstName: parts[0],
            lastName: '',
        };
    }

    // Otherwise, the last part is the last name
    var lastName = parts.pop();

    // The remaining parts are the first name(s)
    var firstName = parts.join(' ');

    // Return an object with the first and last names
    return {
        firstName: firstName,
        lastName: lastName,
    };
};

export const getDayAfterTwoDays = () => {
    // Get today's date
    const today = new Date();

    // Add 2 days
    today.setDate(today.getDate() + 2);

    // Get the day and month
    const day = today.getDate();
    const month = today.toLocaleString('default', { month: 'long' });

    // Determine the suffix
    let suffix = 'th';
    if (day === 1 || day === 21 || day === 31) {
        suffix = 'st';
    } else if (day === 2 || day === 22) {
        suffix = 'nd';
    } else if (day === 3 || day === 23) {
        suffix = 'rd';
    }

    return `${month} ${day}${suffix}`;
};

export const getWordCount = (text: string): number => {
    console.log(text)
    let wordCount = text?.trim().split(/\s+/).filter(Boolean)?.length;

    if (text) return wordCount;

    return 0;
};
export function getRenewDate(timestamp: number): string {
    const date = new Date(timestamp * 1000);

    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };

    const formattedDate = `Renews ${date.toLocaleDateString('en-US', options)}`;

    return formattedDate;
}

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));


export function extractTitles(text:string) {
    const regex = /\d+\.\s\*\*(.*?)\*\*/g;
    let titles = [];
    let match;
  
    while ((match = regex.exec(text)) !== null) {
      titles.push(match[1].trim());
    }
  
    return titles;
  }