
import React from 'react';
import type { FC } from 'react';
import {ReactComponent as LoaderIcon} from '~assets/writer-loader.svg';
import type { LoaderProps } from './Loader.types';

import './Loader.styles.scss';

export const WriterLoader: FC<LoaderProps> = ({ size, color, relative }) => {
    const loaderStyle = {
        width: size || '80px',
        height: size || '80px',
    };
    return (
        <div
            className="w-100 h-100 d-flex justify-content-center align-items-center"
            style={{ position: 'absolute' }}
        >
            <LoaderIcon style={loaderStyle} className='writer-loader'/>
        </div>
    );
};
