import type { FC } from 'react';
import React from 'react';

import { icons } from '~constants';
import type { SubsriptionDetailsProps } from './SubscriptionDetails.types';

import { getCardImage } from '~utils';
import './SubscriptionDetails.styles.scss';
import { useTranslation } from 'react-i18next';

export const SubscriptionDetails: FC<SubsriptionDetailsProps> = (props) => {
    const { billingData, cardData } = props;
    const cardIcon = getCardImage(cardData?.brand);
    const { t } = useTranslation();

    const onEditClick = () => {
        if (billingData?.updatePaymentUrl) {
            window.open(billingData.updatePaymentUrl, '_self');
        }
    };

    return (
        <div className="subscription-details">
            <div className="plan-item card-info">
                <img src={cardIcon} alt="card" />
                <div className="card-details">
                    {cardData ? (
                        <>
                            <h3>
                                {cardData.brand} {t('ending_in')}{' '}
                                {cardData.cardEnding}
                            </h3>
                            <p>
                                {t('expiry')} {cardData.expiryMonth}/
                                {cardData.expiryYear}
                            </p>
                        </>
                    ) : (
                        <p>{t('no_card_data')}</p>
                    )}
                </div>
            </div>
            <div className="plan-item billing-email">
                <div className="email-icon">
                    <img src={icons.email} alt="email" />
                </div>
                {billingData?.billingEmail && <p>{billingData.billingEmail}</p>}
            </div>
            {billingData?.updatePaymentUrl && (
                <button className="edit-btn" onClick={onEditClick}>
                    {t('update_card')}
                    <img src={icons.edit} alt="edit" />
                </button>
            )}
        </div>
    );
};
