import type { FC } from 'react';
import React, { useState } from 'react';

import { useSnackbar } from '~hooks';
import type { ResubscribeProps } from './Resubscribe.types';
import { reSubscription } from '../PaymentPlan.functions';

import './Resubscribe.styles.scss';
import { logger } from '~utils/logger';
import { useTranslation } from 'react-i18next';

export const Resubscribe: FC<ResubscribeProps> = (props) => {
    const { token, customerId } = props;

    const { t } = useTranslation();
    const { infoSnackbar, successSnackbar, errorSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const handleResubscribe = async () => {
        setLoading(true);
        infoSnackbar('Resubscribing...');
        const res = await reSubscription(token, customerId);
        logger.info('Resubscribe result >> ' + res);
        if (res === 'Success') {
            successSnackbar('Subscription started successfully');
            window.location.reload();
        } else {
            errorSnackbar(
                'Something went wrong, try again. This is likely because your bank declined the charge, please try again with a different card.',
            );
            setLoading(false);
        }
    };

    if (props.status === 'active') {
        return null;
    }

    return (
        <div className="edit-payment-plan">
            <button onClick={handleResubscribe} disabled={loading}>
                {t('resubscribe')}
            </button>
            <p>{t('only_599')}</p>
        </div>
    );
};
