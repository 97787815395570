import { Spinner } from "react-bootstrap"
import React from 'react';

import type { FC } from 'react';
import type{ LoaderProps } from "./Loader.types";

export const Loader: FC<LoaderProps> = ({ size, color }) => {
  const loaderStyle = {
    width: size || '20px',
    height: size || '20px',
    color: color || '#262626',
  };
  return <div className="w-100 h-100 d-flex justify-content-center align-items-center">
    <Spinner animation="border" variant="secondary" style={loaderStyle} />
  </div>
}
