import { icons } from '~constants';

export const getCardImage = (brand: string | undefined) => {
    switch (brand) {
        case 'visa':
            return icons.visa;
        case 'mastercard':
            return icons.mastercard;
        case 'amex':
            return icons.amex;
        case 'discover':
            return icons.discover;
        default:
            return icons.card;
    }
};
export const getDashboardCardImage = (brand: string | undefined) => {
    switch (brand) {
        case 'visa':
            return icons.dashboardVisa;
        case 'mastercard':
            return icons.dashboardMasterCard;
        case 'amex':
            return icons.dashboardAmex;
        case 'discover':
            return icons.dashboardDiscover;
        case 'unionpay':
            return icons.dashboardUnionPay;
        case 'jcb':
            return icons.dashboardJCB;
        default:
            return icons.dashboardCard;
    }
};
