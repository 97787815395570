import React, { FC, useState } from 'react';
import { icons } from '~constants';
import { useScreenSize, useSnackbar } from '~hooks';
import { logger } from '~utils';
import { semesterPlanUpgrade } from '../PaymentPlan.functions';
import { emitSemeseterPlanEvent } from '~utils/advertisingEvents';

import './SemesterPlan.styles.scss';
import { useTranslation } from 'react-i18next';

type Props = {
    token: string | undefined;
    customerId: string;
};

export const SemesterPlan: FC<Props> = (props) => {
    const [loading, setLoading] = useState(false);

    const { token, customerId } = props;
    const { t } = useTranslation();

    const { isMobile } = useScreenSize();
    const { infoSnackbar, successSnackbar, errorSnackbar } = useSnackbar();

    const onUpgradeBtnClick = async () => {
        setLoading(true);
        console.log('Upgrade button clicked');
        infoSnackbar(`${t('upgrading_sub')}...`);
        const res = await semesterPlanUpgrade(token, customerId);

        logger.info('semester subscription result >> ' + res);
        if (res === 'Success') {
            await emitSemeseterPlanEvent();
            successSnackbar('Semester subscription started successfully');
            window.location.reload();
        } else {
            errorSnackbar(
                'Something went wrong, try again. This is likely because your bank declined the charge, please try again with a different card.',
            );
            setLoading(false);
        }
    };

    return (
        <div className="semester-plan">
            <div>
                <h3 className="plan-name">{t('semester_plan')}</h3>

                {isMobile && (
                    <div className="spots">
                        <p className="user-discount">
                            {t('new_user_discount')}
                        </p>
                        <p className="places-count">2,271 {t('places_left')}</p>
                    </div>
                )}
                <div className="pricing">
                    <p className="original-price">$71.99</p>
                    <p className="discount-price">$39.99</p>
                </div>
                <p className="billing-cycle">{t('billed_every_3')}</p>

                {!isMobile && (
                    <div className="spots">
                        <p className="user-discount">
                            {t('new_users_discount')}
                        </p>
                        <p className="places-count">2,271 {t('places_left')}</p>
                    </div>
                )}
            </div>

            <button
                className="upgrade-btn"
                onClick={onUpgradeBtnClick}
                disabled={loading}
            >
                {t('upgrade')}
                <img src={icons.rightArrowWhite} alt="arrow" />
            </button>
        </div>
    );
};
